<template>
    <div>
        <!-- slot for parent component to activate the file changer -->
        <div @click="launchFilePicker()">
            <slot name="activator"></slot>
        </div>
        <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
        <input type="file"
               ref="file"
               :name="uploadFieldName"
               :accept="acceptExts"
               @change="onFileChange($event.target.name, $event.target.files)"
               style="display:none">
        <!-- error dialog displays any potential error messages -->
        <v-dialog v-model="errorDialog" max-width="500">
            <v-card style="width:500px;">
                <v-card-text class="subheading pt-5">{{ errorText }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="errorDialog = false" flat>확인</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'image-input',
    data: () => ({
        errorDialog: null,
        errorText: '',
        uploadFieldName: 'file',
        maxSize: 5,
        imageFileFlag: false,
        acceptExts: '.jpg,.jpeg,.png,.bmp,.gif,.pdf'
    }),
    props: {
        // Use "value" to enable using v-model
        value: Object,
        imgType: String,
        allowType: String
    },
    created() {
        if (this.allowType && this.allowType.trim().length != 0) {
            if (this.allowType == 'image.*') {
                this.acceptExts = '.jpg,.jpeg,.png,.bmp'
            }
        }
    },
    methods: {
        launchFilePicker() {
            this.$emit('beforePicker')

            this.$refs.file.value = null;
            this.$refs.file.click();
        },
        onFileChange(fieldName, file) {
            const {maxSize} = this
            let imageFile = file[0]

            if (file.length > 0) {
                let size = imageFile.size / 1024 / 1024
                if ((this.allowType && !imageFile.type.match(this.allowType)) || (!imageFile.type.match('image.*') && !imageFile.type.match('application/pdf'))) {
                    // check whether the upload is an image
                    this.errorDialog = true
                    this.errorText = '이미지 파일을 선택해 주세요'
                } else if (size > maxSize) {
                    // check whether the size is greater than the size limit
                    this.errorDialog = true
                    this.errorText = '이미지 파일 크기가 너무 큽니다. 5MB이하 이미지를 등록해 주세요'
                } else {
                    // Append file into FormData and turn file into image URL
                    let formData = new FormData()
                    let imageURL = URL.createObjectURL(imageFile)
                    formData.append(fieldName, imageFile)
                    // Emit the FormData and image URL to the parent component

                    this.$emit('input', {formData, imageURL, imageFile, 'imgType': this.imgType ? this.imgType : ''})
                }
            }
        }
    }
}
</script>